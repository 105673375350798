// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-client-form-js": () => import("../src/pages/client_form.js" /* webpackChunkName: "component---src-pages-client-form-js" */),
  "component---src-pages-designer-form-js": () => import("../src/pages/designer_form.js" /* webpackChunkName: "component---src-pages-designer-form-js" */),
  "component---src-pages-designer-js": () => import("../src/pages/designer.js" /* webpackChunkName: "component---src-pages-designer-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

